@font-face {
    font-family: 'ML TT Revathi';
    src: url('./fonts/ML_TT_Revathi.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.ml {
    font-family: 'ML TT Revathi', sans-serif;
}
body {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #747d88;
    background-color: #fff;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1{
    color: $black;
    word-break: break-word;
    width: 100%;
}
a {
    text-decoration: none;
}
.swiper-button-prev, .swiper-button-next{
    color: $primary !important;
}
.swiper-pagination-bullet-active{
    background: $primary !important;
}
.text-body {
    color: #747d88 !important;
}
.bg-sports {
    background-color: #85b1d0 !important;
}
/*** Spinner Start ***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .8s ease-out, visibility 0s linear .5s;
    z-index: 99999;
 }

 #spinner.show {
     transition: opacity .8s ease-out, visibility 0s linear .0s;
     visibility: visible;
     opacity: 1;
 }
/*** Spinner End ***/
.bg-sports{
    background-color: #85b1d0 !important;
}
.bg-primary{
    background-color: $primary !important;
}
.bg-secondary{
    background-color: #000000 !important;
}
.back-to-top {
    position: fixed;
    right: 30px;
    bottom: 30px;
    display: flex;
    width: 45px;
    height: 45px;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    z-index: 99;
}
.video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
  }
  
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
.navbar-brand{
    border: 3px solid $primary;
    padding: 0;
    img{
        filter: drop-shadow(4px 2px 1px white);
    }
}
.fw-thin {
    font-weight: 100;
}

.text-uppercase {
    font-size: 14px;
}

/*** Button Start ***/
.btn {
    font-weight: 600;
    transition: .5s;
}

.btn-square {
    width: 32px;
    height: 32px;
}

.btn-sm-square {
    width: 34px;
    height: 34px;
}

.btn-md-square {
    width: 44px;
    height: 44px;
}

.btn-lg-square {
    width: 56px;
    height: 56px;
}

.btn-square,
.btn-sm-square,
.btn-md-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
}

.link-hover {
    transition: 0.5s;
}

.link-hover:hover {
    color: var(--bs-primary) !important;
}


.img-zoomin {
    transition: 0.5s;
}

.img-zoomin:hover {
    transform: scale(1.1);
}

/*** Topbar Start ***/
.sticky-top {
    transition: 0.5s;
    background: var(--bs-white);
}

.sticky-header{
    &.sticky-active{
        top:-55px !important;
    }
}
.topbar {
    padding: 10px 0;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
    opacity: 1;
    visibility: visible;
    &.hidden{
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    }
    .top-info {
        font-family: 'Montserrat', sans-serif;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 1px;
        display: flex;
        align-items: center;
    }
    .top-link {
        font-family: 'Montserrat', sans-serif;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 1px;
        display: flex;
        align-items: center;
        a {
            font-size: medium;
            font-weight: 400;
            font-family: 'Montserrat', sans-serif;
            letter-spacing: 1px;
            small:hover {
                color: var(--bs-secondary) !important;
                transition: 0.5s;
                i {
                    color: var(--bs-primary) !important;
                }
            }
        }
    }
}


#note {
    position: relative;
    animation: noteAnimate 10s infinite;
    animation-timing-function: ease-in-out;
    display: flex;
    align-items: center;
}

@keyframes noteAnimate {
    from {left: -735px;}
    to {left: 735px;}
}

/*** Topbar End ***/

/*** Navbar Start ***/
.navbar .navbar-nav .nav-link {
    padding: 10px 7px;
    font-weight: 600;
    font-family: 'Fredoka', sans-serif;
    font-size: 17px;
    transition: .5s;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active,
.sticky-top.bg-white .navbar .navbar-nav .nav-link:hover,
.sticky-top.bg-white .navbar .navbar-nav .nav-link.active {
    color: var(--bs-primary);
}

.navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    vertical-align: middle;
    margin-left: 8px;
}



.dropdown .dropdown-menu a:hover {
    background: var(--bs-secondary);
    color: var(--bs-primary);
}

.navbar .nav-item:hover .dropdown-menu {
    transform: rotateX(0deg);
    visibility: visible;
    background: var(--bs-light) !important;
    border-radius: 10px !important;
    transition: .5s;
    opacity: 1;
}

.owl-nav{
    display: flex;
    justify-content: end;
}
.owl-prev, .owl-next{
    background: #ac2a29ab;
    padding: 0.5rem;
    margin: 0.1rem;
    color: #FFF;
    border: none;
}
#searchModal .modal-content {
    background: rgba(255, 255, 255, .8);
}
/*** Navbar End ***/


/*** Features Start ***/
.features {
    width: 100%;
    background: linear-gradient(rgba(26, 125, 255, 0.5), rgba(255, 255, 255, 0.3)), url(../../public/assets/img/features-background.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
/*** Features End ***/


/*** Top News Start ***/
.news-2 h3 {
    position: relative;
}

.news-2 h3::after {
    content: "";
    line-height: 1.3rem;
    background: var(--bs-secondary);
    position: absolute;
    width: 68%;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    right: 0;
    opacity: 0.2;
}
/*** Top News End ***/


/*** Latest News Start ***/
.latest-news .latest-news-carousel.owl-carousel{
    position: relative;
}

.latest-news .latest-news-carousel.owl-carousel .owl-nav .owl-prev {
    position: absolute;
    top: -65px;
    right: 70px;
    font-size: 20px;
    padding: 2px 20px;
    transition: 0.5s;
    margin-right: 10px;
    border: 1px solid var(--bs-primary);
    color: var(--bs-dark);
    border-radius: 20px;
}

.latest-news .latest-news-carousel.owl-carousel .owl-nav .owl-next {
    position: absolute;
    top: -65px;
    right: 0;
    font-size: 20px;
    margin-left: 10px;
    padding: 2px 20px;
    transition: 0.5s;
    border: 1px solid var(--bs-primary);
    color: var(--bs-dark);
    border-radius: 20px;
}

.latest-news .latest-news-carousel.owl-carousel .owl-nav .owl-prev:hover,
.latest-news .latest-news-carousel.owl-carousel .owl-nav .owl-next:hover {
    background: var(--bs-primary);
    color: var(--bs-white);
    border-radius: 10px;
}
/*** Latest News End ***/


/*** Whats New Start ***/
.populer-news .whats-carousel.owl-carousel {
    position: relative;
}

.populer-news .whats-carousel.owl-carousel .owl-nav .owl-prev {
    position: absolute;
    top: -80px;
    right: 70px;
    font-size: 20px;
    padding: 2px 20px;
    transition: 0.5s;
    margin-right: 10px;
    border: 1px solid var(--bs-primary);
    color: var(--bs-dark);
    border-radius: 20px;
}

.populer-news .whats-carousel.owl-carousel .owl-nav .owl-next {
    position: absolute;
    top: -80px;
    right: 0;
    font-size: 20px;
    margin-left: 10px;
    padding: 2px 20px;
    transition: 0.5s;
    border: 1px solid var(--bs-primary);
    color: var(--bs-dark);
    border-radius: 20px;
}

.populer-news .whats-carousel.owl-carousel .owl-nav .owl-prev:hover,
.populer-news .whats-carousel.owl-carousel .owl-nav .owl-next:hover {
    background: var(--bs-primary);
    color: var(--bs-white);
    border-radius: 10px;
}

.populer-news .tab-class .nav-item a.active {
    background: var(--bs-primary) !important;
}

.lifestyle .lifestyle-item {
    position: relative;
    overflow: hidden;
}

.lifestyle .lifestyle-item img {
    transition: 0.5s;
}

.lifestyle .lifestyle-item:hover img {
    transform: scale(1.2);
}

.lifestyle .lifestyle-item .lifestyle-content {
    position: absolute;
    width: 100%; 
    height: 100%; 
    top: 0; 
    left: 0; 
    padding: 20px; 
    display: flex; 
    flex-direction: column; 
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}
/*** Whats New End ***/


/*** Banner Section start ***/
.banner-2 {
    position: relative;
}

.banner-content-2 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: linear-gradient(rgba(26, 125, 255, 0.7), rgba(255, 255, 255, 0.7));
    z-index: 2;
}
/*** Banner Section End ***/


/*** Footer Start ***/
.footer button:hover {
    background: var(--bs-white) !important;
    color: var(--bs-primary) !important;
}

.footer .footer-item-1 .line-h {
    line-height: 38px;
}

.footer .footer-item-1 a i {
    transition: 0.5s;
}

.footer .footer-item-1 a:hover i {
    color: var(--bs-primary) !important;
}

.footer .footer-item-3 a {
    line-height: 38px;
}

.footer .footer-item-2 a {
    transition: 0.5s;
}

.footer .footer-item-2 a:hover {
    color: var(--bs-primary) !important;
}

.footer .footer-item-3 a {
    transition: 0.5s;
}

.footer .footer-item-3 a:hover {
    color: var(--bs-primary) !important;
}

.copyright {
    border-top: 1px solid rgba(255, 255, 255, 0.08);
}
/*** Footer End ***/



/* Base styles */
.epaper {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
}

.epaper img {
    width: 100%;
    height: auto;
    display: block;
    max-width: 100%;
}

.main-image{
    width: 950px;
    margin: auto;
}

.mobile-e-panel{
    .close-btn{
        display: none;
    }
}
.epaper-menu{
    display: none;
}
.number{
    font-family: auto;
}