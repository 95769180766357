@import './variables';
.container-fluid.sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;

  .topbar {
    transition: top 0.3s;
    &.hidden {
      top: -100px;
    }
  }

  .navbar {
    background: $primary !important;
    .nav-link {
      color: $light !important;
      filter: drop-shadow(1px 1px 0px black);
      &:hover {
        color: $body-bg !important;
      }
    }

    .navbar-toggler {
      border-color: $primary;

      .fa-bars {
        color: $primary;
      }
    }

    .btn-search {
      background: $light;
      border-color: $primary;
      
      .fa-search {
        color: $primary;
      }
    }
  }
}