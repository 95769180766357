$theme-colors: (
  "primary": #57a400,
  "secondary": #2ecc71,
  "success": #28a745,
  "info": #17a2b8,
  "warning": #ffc107,
  "danger": #dc3545,
  "light": #f8f9fa,
  "dark": #343a40,
);
$primary:#57a400;
$secondary: #2ecc71;
$light: #f8f9fa;
$body-bg: #f5f5f5;
$font-family-base: 'Arial, sans-serif';