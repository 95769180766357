
/* Medium screens */
@media (max-width: 1400px) {
    .sticky-top .top-link {
        display: none;
    }
    
}

@media (min-width: 1200px) {
    .navbar .nav-item .dropdown-menu {
        display: block;
        visibility: hidden;
        top: 100%;
        font-weight: 400;
        font-family: 'Fredoka', sans-serif;
        transform: rotateX(-75deg);
        transform-origin: 0% 0%;
        border: 0;
        transition: .5s;
        opacity: 0;
    }
}

@media (min-width: 1200px) {
    .navbar .navbar-collapse .border-top {
        border-top: none !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .epaper {
        padding: 10px;
    }
}

/* Small screens */
@media screen and (max-width: 767px) {    
    .epaper {
        padding: 5px;
    }
    .top-info{
        display: none !important;
    }
    .top-link{
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }
    .epaper-menu{
        display: inline;
    }
    .mobile-e-panel{
        position: fixed;
        z-index: 9999;
        background: rgb(0 0 0 / 85%);
        width: 100%;
        height: 100%;
        overflow: scroll;
        right: 0;
        top: 0;
        border-left: 2px solid #000000bf;
        padding: 1rem;
        visibility: hidden;
        opacity: 0;
        transform: translateX(100%);
        transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
        &.active{
            visibility: visible;
            opacity: 1;
            transform: translateX(0);
            transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
        }
        .close-btn{
            display: inline !important;
            position: absolute;
            z-index: 99;
            top: 5px;
            right: 5px;
        }
        .container{
            .row{
                display: flex;

                .col-md-6{
                    width: 30%;
                }
            }
        }
    }
}

/* Extra small screens */
@media screen and (max-width: 575px) {
    .epaper {
        padding: 3px;
    }
}